import { default as accountmnBwgKSh6TMeta } from "/home/runner/work/ia-space/ia-space/src/pages/account.vue?macro=true";
import { default as diongsYPJHn91NMeta } from "/home/runner/work/ia-space/ia-space/src/pages/artist/dion.vue?macro=true";
import { default as arWqHUb4Mld9Meta } from "/home/runner/work/ia-space/ia-space/src/pages/features/ar.vue?macro=true";
import { default as editor9nQisRUGyEMeta } from "/home/runner/work/ia-space/ia-space/src/pages/features/editor.vue?macro=true";
import { default as featuresJm2Xe1SczhMeta } from "/home/runner/work/ia-space/ia-space/src/pages/features/features.vue?macro=true";
import { default as inpagegH4LWBgRNnMeta } from "/home/runner/work/ia-space/ia-space/src/pages/features/inpage.vue?macro=true";
import { default as _91id_93rWPqnaOhPBMeta } from "/home/runner/work/ia-space/ia-space/src/pages/gallery/[id].vue?macro=true";
import { default as indexRJloU3AZB2Meta } from "/home/runner/work/ia-space/ia-space/src/pages/index.vue?macro=true";
import { default as lumas0UlY14Z9o3Meta } from "/home/runner/work/ia-space/ia-space/src/pages/pitch/lumas.vue?macro=true";
import { default as samsung_45floorVxIegPNDolMeta } from "/home/runner/work/ia-space/ia-space/src/pages/pitch/samsung-floor.vue?macro=true";
import { default as samsung_45wallns6EuOrEo6Meta } from "/home/runner/work/ia-space/ia-space/src/pages/pitch/samsung-wall.vue?macro=true";
import { default as pricingJE6pMNBSgMMeta } from "/home/runner/work/ia-space/ia-space/src/pages/pricing.vue?macro=true";
import { default as _91id_93dsNIjAHKXCMeta } from "/home/runner/work/ia-space/ia-space/src/pages/templates/[id].vue?macro=true";
import { default as indexTOlINEwc9WMeta } from "/home/runner/work/ia-space/ia-space/src/pages/templates/index.vue?macro=true";
import { default as newe9kaL23Vk1Meta } from "/home/runner/work/ia-space/ia-space/src/pages/templates/new.vue?macro=true";
import { default as newLocal9vH68Qub3KMeta } from "/home/runner/work/ia-space/ia-space/src/pages/templates/newLocal.vue?macro=true";
import { default as panelOTeScYJ9LPMeta } from "/home/runner/work/ia-space/ia-space/src/pages/templates/panel.vue?macro=true";
import { default as toolkitDKoLz4bZZyMeta } from "/home/runner/work/ia-space/ia-space/src/pages/toolkit.vue?macro=true";
export default [
  {
    name: accountmnBwgKSh6TMeta?.name ?? "account",
    path: accountmnBwgKSh6TMeta?.path ?? "/account",
    meta: accountmnBwgKSh6TMeta || {},
    alias: accountmnBwgKSh6TMeta?.alias || [],
    redirect: accountmnBwgKSh6TMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/account.vue").then(m => m.default || m)
  },
  {
    name: diongsYPJHn91NMeta?.name ?? "artist-dion",
    path: diongsYPJHn91NMeta?.path ?? "/artist/dion",
    meta: diongsYPJHn91NMeta || {},
    alias: diongsYPJHn91NMeta?.alias || [],
    redirect: diongsYPJHn91NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/artist/dion.vue").then(m => m.default || m)
  },
  {
    name: arWqHUb4Mld9Meta?.name ?? "features-ar",
    path: arWqHUb4Mld9Meta?.path ?? "/features/ar",
    meta: arWqHUb4Mld9Meta || {},
    alias: arWqHUb4Mld9Meta?.alias || [],
    redirect: arWqHUb4Mld9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/features/ar.vue").then(m => m.default || m)
  },
  {
    name: editor9nQisRUGyEMeta?.name ?? "features-editor",
    path: editor9nQisRUGyEMeta?.path ?? "/features/editor",
    meta: editor9nQisRUGyEMeta || {},
    alias: editor9nQisRUGyEMeta?.alias || [],
    redirect: editor9nQisRUGyEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/features/editor.vue").then(m => m.default || m)
  },
  {
    name: featuresJm2Xe1SczhMeta?.name ?? "features-features",
    path: featuresJm2Xe1SczhMeta?.path ?? "/features/features",
    meta: featuresJm2Xe1SczhMeta || {},
    alias: featuresJm2Xe1SczhMeta?.alias || [],
    redirect: featuresJm2Xe1SczhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/features/features.vue").then(m => m.default || m)
  },
  {
    name: inpagegH4LWBgRNnMeta?.name ?? "features-inpage",
    path: inpagegH4LWBgRNnMeta?.path ?? "/features/inpage",
    meta: inpagegH4LWBgRNnMeta || {},
    alias: inpagegH4LWBgRNnMeta?.alias || [],
    redirect: inpagegH4LWBgRNnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/features/inpage.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rWPqnaOhPBMeta?.name ?? "gallery-id",
    path: _91id_93rWPqnaOhPBMeta?.path ?? "/gallery/:id()",
    meta: _91id_93rWPqnaOhPBMeta || {},
    alias: _91id_93rWPqnaOhPBMeta?.alias || [],
    redirect: _91id_93rWPqnaOhPBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/gallery/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRJloU3AZB2Meta?.name ?? "index",
    path: indexRJloU3AZB2Meta?.path ?? "/",
    meta: indexRJloU3AZB2Meta || {},
    alias: indexRJloU3AZB2Meta?.alias || [],
    redirect: indexRJloU3AZB2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lumas0UlY14Z9o3Meta?.name ?? "pitch-lumas",
    path: lumas0UlY14Z9o3Meta?.path ?? "/pitch/lumas",
    meta: lumas0UlY14Z9o3Meta || {},
    alias: lumas0UlY14Z9o3Meta?.alias || [],
    redirect: lumas0UlY14Z9o3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/pitch/lumas.vue").then(m => m.default || m)
  },
  {
    name: samsung_45floorVxIegPNDolMeta?.name ?? "pitch-samsung-floor",
    path: samsung_45floorVxIegPNDolMeta?.path ?? "/pitch/samsung-floor",
    meta: samsung_45floorVxIegPNDolMeta || {},
    alias: samsung_45floorVxIegPNDolMeta?.alias || [],
    redirect: samsung_45floorVxIegPNDolMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/pitch/samsung-floor.vue").then(m => m.default || m)
  },
  {
    name: samsung_45wallns6EuOrEo6Meta?.name ?? "pitch-samsung-wall",
    path: samsung_45wallns6EuOrEo6Meta?.path ?? "/pitch/samsung-wall",
    meta: samsung_45wallns6EuOrEo6Meta || {},
    alias: samsung_45wallns6EuOrEo6Meta?.alias || [],
    redirect: samsung_45wallns6EuOrEo6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/pitch/samsung-wall.vue").then(m => m.default || m)
  },
  {
    name: pricingJE6pMNBSgMMeta?.name ?? "pricing",
    path: pricingJE6pMNBSgMMeta?.path ?? "/pricing",
    meta: pricingJE6pMNBSgMMeta || {},
    alias: pricingJE6pMNBSgMMeta?.alias || [],
    redirect: pricingJE6pMNBSgMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dsNIjAHKXCMeta?.name ?? "templates-id",
    path: _91id_93dsNIjAHKXCMeta?.path ?? "/templates/:id()",
    meta: _91id_93dsNIjAHKXCMeta || {},
    alias: _91id_93dsNIjAHKXCMeta?.alias || [],
    redirect: _91id_93dsNIjAHKXCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/templates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTOlINEwc9WMeta?.name ?? "templates",
    path: indexTOlINEwc9WMeta?.path ?? "/templates",
    meta: indexTOlINEwc9WMeta || {},
    alias: indexTOlINEwc9WMeta?.alias || [],
    redirect: indexTOlINEwc9WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: newe9kaL23Vk1Meta?.name ?? "templates-new",
    path: newe9kaL23Vk1Meta?.path ?? "/templates/new",
    meta: newe9kaL23Vk1Meta || {},
    alias: newe9kaL23Vk1Meta?.alias || [],
    redirect: newe9kaL23Vk1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/templates/new.vue").then(m => m.default || m)
  },
  {
    name: newLocal9vH68Qub3KMeta?.name ?? "templates-newLocal",
    path: newLocal9vH68Qub3KMeta?.path ?? "/templates/newLocal",
    meta: newLocal9vH68Qub3KMeta || {},
    alias: newLocal9vH68Qub3KMeta?.alias || [],
    redirect: newLocal9vH68Qub3KMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/templates/newLocal.vue").then(m => m.default || m)
  },
  {
    name: panelOTeScYJ9LPMeta?.name ?? "templates-panel",
    path: panelOTeScYJ9LPMeta?.path ?? "/templates/panel",
    meta: panelOTeScYJ9LPMeta || {},
    alias: panelOTeScYJ9LPMeta?.alias || [],
    redirect: panelOTeScYJ9LPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/templates/panel.vue").then(m => m.default || m)
  },
  {
    name: toolkitDKoLz4bZZyMeta?.name ?? "toolkit",
    path: toolkitDKoLz4bZZyMeta?.path ?? "/toolkit",
    meta: toolkitDKoLz4bZZyMeta || {},
    alias: toolkitDKoLz4bZZyMeta?.alias || [],
    redirect: toolkitDKoLz4bZZyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ia-space/ia-space/src/pages/toolkit.vue").then(m => m.default || m)
  }
]